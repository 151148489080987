import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  src: "/website/contact.html",
  style: {
    "width": "100%",
    "height": "100%",
    "display": "block"
  },
  marginwidth: "0",
  marginheight: "0",
  frameborder: "0"
};
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'contactUs',

  setup(__props) {
    const router = useRouter();

    const goSystem = route => {
      router.push({
        path: `/${route}`
      });
    };

    onMounted(() => {
      window.addEventListener('message', function (e) {
        // 监听 message 事件
        console.log(e);

        if (e.data && e.data.type === 'gopage') {
          goSystem(e.data.route);
        }
      });
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("iframe", _hoisted_1);
    };
  }

};